<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.status"
          placeholder="状态"
          clearable
        >
          <Option
            v-for="(item, index) in contractTypes"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="contractSchedule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="审批提交时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >

        <Button
          size="small"
          type="success"
          class="m-l-5"
          @click="handleDownload"
          >导出列表</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <MaterialHistory ref="screenApprovalDetail"></MaterialHistory>
  </div>
</template>

<script>
import MaterialHistory from './MaterialHistory'
import { getOrderfileApprovalPage } from '@/api/order/orderfileV2'
// import { getcategorylist } from '@/api/scp/scpbusiness'

import { ParseDate, GetDateStr, GetCurrentSchedule } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { formatContractStatus } from '@/utils/tagStatus'

export default {
  components: { MaterialHistory },
  data () {
    return {
      contractSchedule: '',
      contractTypes: [
        { id: 0, name: '草稿' },
        { id: 2, name: '已中止' },
        { id: 4, name: '审批中' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
      ],

      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        status: null,
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '合同名称',
          key: 'contractName'
        },
        { title: '广告主', key: 'advertiserName' },
        { title: '品牌', key: 'brandName' },
        { title: '行业', key: 'industryName' },
        {
          title: '起止日期',
          key: 'startDate',
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
          }
        },
        { title: '提交时间', key: 'submitTime' },
        { title: '审批时间', key: 'approvalTime' },
        { title: '发布类型', key: 'typeName' },
        { title: '代理商', key: 'companyName' },

        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return formatContractStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$refs.screenApprovalDetail.showModal(params.row.id, params.row.orderId)
                  }
                }
              },
              '审批明细'
            )

            return h('div', [detailButton])
          }
        }
      ]

    }
  },
  created () {
    // getcategorylist({}).then(res => {
    //   this.contractTypes = res
    // })
    this.initPageData()
  },
  methods: {

    scheduleChange () {
      const shcedule = this.contractSchedule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.submitStartTime = ''
        this.query.submitEndTime = ''
      } else {
        this.query.submitStartTime = GetDateStr(shcedule[0], 1)
        this.query.submitEndTime = GetDateStr(shcedule[1], 1)
      }
    },

    initPageData () {
      getOrderfileApprovalPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    // CreateCustomer () {
    //   this.$emit('on-create')
    // },
    // handleShowDetail (customerId) {
    //   this.$emit('on-detail', customerId)
    // },

    handleDownload () {
      const filename = '画面审批记录(' + ParseDate(new Date()) + ').xlsx'
      const params = {
        keyword: this.query.keyword,
        status: this.query.status,
        submitStartTime: this.query.submitStartTime,
        submitEndTime: this.query.submitEndTime
      }
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-order/v2/orderfile/exportOrderfileApproval',
        params,
        filename
      )
    }
  },
  watch: {
  },
  computed: {
  }
}
</script>
